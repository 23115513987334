import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Hero'

export const HomePageTemplate = ({
  title,
  description,
  legal,
  socialMedia,
  downloadLinks,
  hero
}) => (
  <Layout
    title={title}
    description={description}
    legal={legal}
    socialMedia={socialMedia}>
    <Hero
      backgroundImage={hero.backgroundImage}
      mainImages={hero.mainImages}
      avatar={hero.avatar}
      title={hero.title}
      description={hero.description}
      downloadLinks={downloadLinks}
    />
  </Layout>
)

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <HomePageTemplate
      title={frontmatter.title}
      description={frontmatter.description}
      legal={frontmatter.legal}
      socialMedia={frontmatter.socialMedia}
      downloadLinks={frontmatter.downloadLinks}
      hero={frontmatter.hero}
    />
  )
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        legal {
          label
          value
        }
        socialMedia {
          icon
          url
        }
        downloadLinks {
          title
          links {
            icon
            url
            altText
          }
        }
        hero {
          title
          description
          avatar
          backgroundImage
        }
      }
    }
  }
`
