import React from 'react'
import Slider from 'react-slick';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'idk'
};

const ImagesCarousel = ({ items }) => (
  <Slider {...settings}>
    {items.map((item, i) => (
      <div key={i} className="carousel-item">
        <img src={item.image} />
      </div>
    ))}
  </Slider>
)

export default ImagesCarousel
