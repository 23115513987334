import React from 'react'

const DownloadLinks = ({
  title,
  items
}) => (
  items ? (
    <div className="download-links">
      <h2>{title}</h2>
      <ul>
        {items.map((item, i) => (
          <li key={i}>
            <a href={item.url}><img src={item.icon} alt={item.altText} /></a>
          </li>
        ))}
      </ul>
    </div>
  ) : null
)

export default DownloadLinks
