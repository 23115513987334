import React from 'react'
import marked from 'marked'

import ImagesCarousel from '../ImagesCarousel'
import DownloadLinks from '../DownloadLinks'

const Hero = ({
  backgroundImage,
  mainImages,
  avatar,
  title,
  description,
  downloadLinks
}) => (
  <article className="hero" style={{backgroundImage: `url(${backgroundImage})`}}>
    {mainImages ? (
      <main>
        {mainImages.length > 1 ? (
          <ImagesCarousel items={mainImages} />
        ) : (
          <img src={mainImages[0].image} />
        )}
      </main>
    ) : null}

    <aside>
      <img src={avatar} className="avatar" />
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{__html: marked(description)}} />
      <DownloadLinks
        title={downloadLinks.title}
        items={downloadLinks.links}
      />
    </aside>
  </article>
)

export default Hero
